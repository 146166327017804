import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { oneLineTrim } from 'common-tags';
import { graphql } from 'gatsby';
import { Global, css } from '@emotion/core';
import styled from '@emotion/styled';
import Modal from 'react-modal';

import Layout from '../components/layout';
import Header from '../components/header';
import Section from '../components/Section';
import SectionHeader from '../components/SectionHeader';
import { unit, BREAKPOINTS } from '../theme';

import diagram1 from '../images/diagram1.png';
import diagram2 from '../images/diagram2.png';

Modal.setAppElement('#___gatsby');

const NavItem = styled.li({
  margin: 0,
  display: 'flex',
  alignItems: 'center',
});

const NavLink = styled.a({
  color: '#222',
  fontSize: 18,
  fontWeight: 400,
  textDecoration: 'none',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: '0 10px',
  ':hover': {
    color: '#fb78c5',
  },
});

export default function IndexPage({
  data: { locationContent, thingsToDoContent, headerImage },
}) {
  const navRef = React.createRef();

  const [sticky, setSticky] = useState(false);
  useEffect(() => {
    const nav = navRef.current;
    let pos = nav.offsetTop;
    window.addEventListener('resize', function() {
      pos = nav.offsetTop;
    });
    window.addEventListener('scroll', function() {
      if (window.scrollY > pos) {
        setSticky(true);
      } else if (window.scrollY < pos) {
        setSticky(false);
      }
    });
  }, []);

  return (
    <Layout>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
        title="Jonathan and Lisa are getting married"
        meta={[
          {
            name: `description`,
            content: 'Friday 30th August 2019 - Trieste, Italy',
          },
        ]}
      />
      <Helmet>
        <link
          href="//fonts.googleapis.com/css?family=Raleway:400,300,600"
          rel="stylesheet"
          type="text/css"
        />
      </Helmet>
      <Global
        styles={css`
          body {
            overflow-x: hidden;
            font-size: 18px;
          }

          .ReactModal__Body--open {
            overflow-y: hidden;
          }

          ${BREAKPOINTS.normal} {
            p {
              font-size: 20px;
            }
          }
        `}
      />

      <Header
        backgroundPositionX="left"
        image={headerImage}
        overlay="linear-gradient(rgba(191, 63, 125, 0.5), rgba(0,0,0,0.0))"
        css={{
          width: '100vw',
          marginLeft: '-50vw',
          marginRight: '-50vw',
          left: '50%',
          right: '50%',
          position: 'relative',
          height: '70vh',
          display: 'flex',
          [BREAKPOINTS.normal]: {
            height: '85vh',
          },
        }}
      >
        <h1
          css={{
            color: '#fff',
            fontWeight: 600,
            fontSize: 40,
            textAlign: 'center',
            textShadow: '0 1px 2px rgba(0, 0, 0, 0.2)',
            marginBottom: unit(1),
            [BREAKPOINTS.normal]: {
              marginBottom: 0,
              fontSize: '90px',
            },
          }}
        >
          Jonathan & Lisa
        </h1>
        <h2
          css={{
            textAlign: 'center',
            color: '#fff',
            fontWeight: 600,
            fontSize: 26,
            textShadow: '0 1px 2px rgba(0, 0, 0, 0.2)',
            [BREAKPOINTS.normal]: {
              fontSize: '40px',
            },
          }}
        >
          Friday 30th August 2019
        </h2>
        <hr
          css={{
            margin: 0,
            width: 200,
            borderTop: '3px solid #fff',
            [BREAKPOINTS.normal]: {
              width: 380,
              borderTop: '4px solid #fff',
            },
          }}
        />
      </Header>

      <nav
        ref={navRef}
        css={{
          width: '100vw',
          background: '#fff',
          height: 50,
          zIndex: 400,
          boxShadow: '0 2px 4px rgba(0,0,0,0.15), 0 1px 0 rgba(0,0,0,0.08)',
          top: 0,
          boxSizing: 'border-box',
          position: sticky ? 'fixed' : 'relative',
        }}
      >
        <ul
          css={{
            margin: 0,
            display: 'flex',
            listStyleType: 'none',
            padding: 0,
            height: '100%',
            overflowX: 'scroll',
            justifyContent: 'space-around',
            [BREAKPOINTS.xsmall]: {
              overflowX: 'inherit',
            },
            [BREAKPOINTS.normal]: {
              maxWidth: '50%',
              margin: '0 auto',
            },
          }}
        >
          <NavItem>
            <NavLink href="#location">Location</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#accommodation">Stay</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#guide">Guide</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#gifts">Gifts</NavLink>
          </NavItem>
        </ul>
      </nav>

      <div css={{ height: sticky ? 50 : 0 }} />
      <div
        className="container"
        css={{ [BREAKPOINTS.normal]: { width: '50%' } }}
      >
        <div css={{ marginTop: unit(6), marginBottom: -40 }}>
          <p
            css={{
              fontSize: 24,
              fontWeight: 400,
              textAlign: 'center',
            }}
          >
            🎉 Jonathan & Lisa are married! 🎉
          </p>
          <p
            css={{
              fontSize: 20,
              fontWeight: 400,
            }}
          >
            Thank you to everyone who joined us for our special day. In case you
            would like to visit Trieste again we will leave all the travel
            information for you here.
          </p>
        </div>

        <Section
          id="location"
          css={{
            h4: {
              marginTop: 40,
            },
          }}
        >
          <SectionHeader>Getting there</SectionHeader>

          <div dangerouslySetInnerHTML={{ __html: locationContent.html }} />

          <div
            css={{
              height: `500px`,
            }}
          >
            <iframe
              src={oneLineTrim`
              https://www.google.com/maps/embed/v1/place?
              key=AIzaSyCg6tnfW4uk27Q3h2PaA9bxdOJbyVC5PF8
              &q=Piazza Unità d'Italia, 4, 34121 Trieste TS, Italy
              &zoom=13
            `}
              width="100%"
              height="500"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen
            />
          </div>
        </Section>

        <Section id="accommodation" css={{ marginTop: 20 }}>
          <SectionHeader>Where to stay</SectionHeader>
          <div>
            <p>
              The main thing to keep in mind is that Trieste is hilly and some
              parts of town are very steep. In the potential August heat you
              might not feel like walking up a hill so the two maps below
              roughly show the parts which are more or less flat and those which
              aren’t.
            </p>
            <img src={diagram1} css={{ maxWidth: '100%' }} />
            <img src={diagram2} css={{ maxWidth: '100%' }} />
            <p css={{ marginTop: 20 }}>
              The other big issue is parking - there’s just not much of it in
              the centre of town so if you are planning to rent a car, it would
              be advisable to find a place with parking.
            </p>
            <p>
              If you don’t have a car, do not worry: the centre of town is
              easily walkable and the bus network is very good.
            </p>
          </div>
        </Section>

        <Section
          id="guide"
          css={{
            h3: {
              marginTop: 40,
            },
            h4: {
              marginTop: 40,
              '&:first-of-type': {
                marginTop: 20,
              },
            },
          }}
        >
          <SectionHeader>Things to do</SectionHeader>
          <div dangerouslySetInnerHTML={{ __html: thingsToDoContent.html }} />
        </Section>

        <Section
          id="gifts"
          css={{
            h3: {
              marginTop: 40,
            },
            h4: {
              marginTop: 40,
              '&:first-of-type': {
                marginTop: 20,
              },
            },
          }}
        >
          <SectionHeader>🎁 Gifts</SectionHeader>
          <div>
            <p>
              Your presence at our wedding is the most important thing to us,
              but should you wish to kindly give us a gift, you can make a cash
              contribution for our honeymoon and house deposit using the bank
              details provided in the email. If it’s easier for you to transfer
              money in another currency let us know and we can give you
              alternative bank details.
            </p>
            <p>
              Alternatively, it would also mean a lot to us if you made a small
              contribution to our chosen charity, which helps with research and
              care of amyotrophic lateral sclerosis:{' '}
              <a href="http://www.alsa.org/donate/">
                http://www.alsa.org/donate/
              </a>
            </p>
            <p>
              We kindly ask you not to get us any physical gifts: we will not be
              able to carry them back to the UK and we have limited space in our
              flat in London.
            </p>
          </div>
        </Section>

        <Section
          css={{
            textAlign: 'center',
            fontSize: 20,
            marginBottom: 60,
          }}
        >
          <SectionHeader css={{ marginBottom: 60 }} />
          <div>🐼 ❤️ 🐷</div>
        </Section>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query IndexPageQuery {
    locationContent: markdownRemark(
      frontmatter: { title: { eq: "Location" } }
    ) {
      id
      html
    }

    thingsToDoContent: markdownRemark(
      frontmatter: { title: { eq: "Things to do" } }
    ) {
      id
      html
    }

    headerImage: file(relativePath: { eq: "illustration.jpg" }) {
      ...HeroImage
    }
  }
`;
