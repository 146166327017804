import React from 'react';

export default function SectionHeader({ children, ...props }) {
  return (
    <h2
      css={{
        borderBottomWidth: 4,
        borderStyle: 'solid',
        borderImage: 'linear-gradient(to right, #fb78c5, #99c98d) 0 0 100%',
        lineHeight: 1.7,
        fontWeight: 400,
      }}
      {...props}
    >
      {children}
    </h2>
  );
}
