import React from 'react';

export default function Section({ children, ...props }) {
  return (
    <section
      css={{
        paddingTop: 60,
        marginBottom: -40,
      }}
      {...props}
    >
      {children}
    </section>
  );
}
