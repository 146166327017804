export const BREAKPOINTS = {
  xsmall: '@media only screen and (min-width: 400px)',
  small: '@media only screen and (min-width: 512px)',
  medium: '@media only screen and (min-width: 768px)',
  normal: '@media screen and (min-width: 1024px)',
  large: '@media screen and (min-width: 1200px)',
  contentContainer: '@media screen and (min-width: 1080px)',
};

export const SIZE_UNIT = 8;

export const unit = (multiplier /*: number */) => multiplier * SIZE_UNIT;
