import React from 'react';
import styled from '@emotion/styled';

import { graphql } from 'gatsby';

import { unit, BREAKPOINTS } from '../theme';

import { backgrounds, hiDPI } from 'polished';

export const heroImageFragment = graphql`
  fragment HeroImage on File {
    crops: childImageSharp {
      small_1: resolutions(width: 375, height: 713, cropFocus: CENTER) {
        base64
        src
      }
      medium_1: resolutions(width: 800, height: 837, cropFocus: CENTER) {
        base64
        src
      }
      large_1: resolutions(width: 1500, height: 837, cropFocus: CENTER) {
        base64
        src
      }
      huge_1: resolutions(width: 2000, height: 837, cropFocus: CENTER) {
        base64
        src
      }
      small_2: resolutions(width: 750, height: 1426, cropFocus: CENTER) {
        base64
        src
      }
      medium_2: resolutions(width: 1600, height: 1674, cropFocus: CENTER) {
        base64
        src
      }
      large_2: resolutions(width: 3000, height: 2800, cropFocus: CENTER) {
        base64
        src
      }
      huge_2: resolutions(width: 4000, height: 2800, cropFocus: CENTER) {
        base64
        src
      }
    }
  }
`;

const imageFragmentDataToStyles = (data, overlay, backgroundPositionX) => {
  const imageSpec = [
    {
      375: data.crops.small_1,
      800: data.crops.medium_1,
      1500: data.crops.large_1,
      2000: data.crops.huge_1,
    },
    {
      375: data.crops.small_2,
      800: data.crops.medium_2,
      1500: data.crops.large_2,
      2000: data.crops.huge_2,
    },
  ];

  return imageSpec.reduce((acc, spec, index) => {
    acc[hiDPI(index)] = Object.values(spec).reduce((acc2, img, index) => {
      let bgs;

      if (index === 0) {
        bgs = {
          [`@media only screen`]: {
            ...backgrounds(
              overlay,
              `url(${img.src}) no-repeat center center`,
              `url(${img.base64}) no-repeat center center`
            ),
            backgroundSize: '100% 100%, cover, cover',
            backgroundPositionX,
          },
        };
      } else {
        bgs = {
          [`@media only screen and (min-width: ${parseInt(
            Object.keys(spec)[index - 1],
            10
          ) + 1}px)`]: {
            ...backgrounds(
              overlay,
              `url(${img.src}) no-repeat center center`,
              `url(${img.base64}) no-repeat center center`
            ),
            backgroundSize: '100% 100%, cover, cover',
            backgroundPositionX,
          },
        };
      }
      return { ...acc2, ...bgs };
    }, {});
    return acc;
  }, {});
};

const Backdrop = styled('section')(
  {
    position: 'relative',
  },
  ({
    image,
    overlay = 'linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0))',
    backgroundPositionX = 'center',
  }) => imageFragmentDataToStyles(image, overlay, backgroundPositionX)
);

const Hero = ({ children, image, overlay, backgroundPositionX, className }) => (
  <Backdrop
    backgroundPositionX={backgroundPositionX}
    overlay={overlay}
    image={image}
    className={className}
  >
    <div
      css={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        top: '-30%',
        position: 'relative',
      }}
    >
      {children}
    </div>
  </Backdrop>
);

export default Hero;
